<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <properties-component-image title="Image" :value="block.image" v-bind="componentProps"/>
        <properties-component-text title="Legend|Légende" :value="block.legend" v-bind="componentProps">
            <b-form-group label-cols="4" :label="'Position|Position'|tr">
                <b-select v-model="block.legendPosition">
                    <option value="top" v-tr>Top|En haut</option>
                    <option value="bottom" v-tr>Bottom|En bas</option>
                    <option value="left" v-tr>Left|Gauche</option>
                    <option value="right" v-tr>Right|Droite</option>
                </b-select>
            </b-form-group>
            <b-form-group label-cols="4" :label="'Theme|Thème'|tr">
                <b-select v-model="block.legendTheme">
                    <option value="transparent" v-tr>Transparent|Transparent</option>
                    <option value="light" v-tr>Light|Clair</option>
                    <option value="dark" v-tr>Dark|Foncé</option>
                </b-select>
            </b-form-group>
            <b-form-group label-cols="4" :label="'Spacing|Espacement'|tr">
                <b-select v-model="block.legendPadding">
                    <option value="0" v-tr>None|Sans</option>
                    <option v-for="n in 50" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                </b-select>
            </b-form-group>
        </properties-component-text>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import PropertiesComponentImage from "@/components/editor/properties/components/properties-component-image.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";

// properties-block-image #hot-reload-debug
export default {
    name: `properties-block-image`,
    components: {PropertiesComponentText, PropertiesComponentImage, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
